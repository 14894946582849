import React, { Component, Fragment, createRef } from "react";
import { getSiteContent } from "../../redux/actions/actions"
import { connect } from "react-redux";
import Helper from '../../services/helper';
import { Container, Row, Col, } from "react-bootstrap";
import Slide1 from "../../images/dashboard.png";
import Slide2 from "../../images/events.png";
import Slide3 from "../../images/event-report.png";
import Slide4 from "../../images/ticket-scan.png";
import Slide5 from "../../images/guest-list.png";
import whatsappFill from "../../images/whatsapp.png";
import emoji from "../../images/emoji.png";
import Sellticket from "../../images/tickets.png";
import SaleMonitor from "../../images/sale-moniter.png";
import ravers from "../../images/ravers.png";
import sellout from "../../images/sellout.png";
import buzz from "../../images/buzz.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Slider from "react-slick";
import emailjs from '@emailjs/browser';
import { isValidPhoneNumber } from 'react-phone-number-input'; // Import phone number validation library
import PhoneInput from 'react-phone-input-2'; // Import phone input library
import 'react-phone-input-2/lib/style.css'; // Import styles for phone input
import { useState } from 'react';
import dummyVideo from "../../images/video/request_code.mp4"
import Card from 'react-bootstrap/Card';

class RequestCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgress: true,
            Modalshow: false,
            formData: {
                firstName: '',
                lastName: '',
                user_whatsapp: '',
                user_city: ''
            },
            errors: {}

        };
        this.form = createRef();
        this.organzer = createRef();
    }
    handleShow = () => {
        this.setState({ Modalshow: true });
    };

    handleClose = () => {
        this.setState({ Modalshow: false });
    };
    componentDidMount() {
        // if (!Helper.IsValidated()) {
        //     this.props.history.push("/");
        // }
        localStorage.setItem("hasVisitedRequestCode", "true");

        this.getAbout();
        this.form.current.focus();
        this.organzer.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    getAbout = async () => {
        const { getSiteContent } = this.props;
        var About = new Object();
        About.title = 'About'
        await getSiteContent(About);
    }

    componentDidUpdate(prevProps) {
        const { isGetSiteContentSuccess: prevIsGetSiteContentSuccess } = prevProps;
        const { isGetSiteContentSuccess } = this.props;

        if (prevIsGetSiteContentSuccess === false && isGetSiteContentSuccess) {

            this.setState({ showProgress: false });
        }

    }
    sendEmail = (e) => {
        e.preventDefault();
        const errors = this.validateForm();
        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return;
        }
        // console.log('-----------------------------------------------')
        const templateParams = {
            from_name: this.state.formData.firstName + " " + this.state.formData.lastName,
            message: `Name: ${this.state.formData.firstName} ${this.state.formData.lastName}  City: ${this.state.formData.user_city} WhatsApp: ${this.state.formData.user_whatsapp}`
        };
        // console.log("this form current ", templateParams);
        emailjs.send('service_4nvcz4h', 'template_y9x71qg', templateParams, 'rmwCRPWi_7CCG0AoM')
            .then(() => {
                console.log('SUCCESS!');
                // this.handleClose();
                this.handleShow();
                this.setState({
                    formData: {
                        firstName: '',
                        lastName: '',
                    user_whatsapp: '',
                    user_city: ''
                    }
                });
            }, (error) => {
                console.log('FAILED...', error.text);
            });
    };

    validateForm = () => {
        const { firstName, lastName, user_whatsapp, user_city } = this.state.formData;
        const errors = {};
        // console.log(user_whatsapp, '---------------------------', isValidPhoneNumber(`+${user_whatsapp}`))
        if (!firstName) errors.firstName = "First Name is required.";
        if (!lastName) errors.lastName = "First Name is required.";
        if (!user_whatsapp) {
            errors.user_whatsapp = "WhatsApp number is required.";
        } else if (!isValidPhoneNumber(`+${user_whatsapp}`)) {
            errors.user_whatsapp = "Please enter a valid WhatsApp number.";
        }
        if (!user_city) errors.user_city = "City is required.";

        return errors;
    };

    handleChange = (name, value) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            },
            errors: {
                ...prevState.errors,
                [name]: null // Clear the error for the specific field
            }
        }));
    };
    render() {
        const { siteContent } = this.props
        const { showProgress, Modalshow, formData, errors } = this.state
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            autoplay: true,
            slidesToScroll: 1,
            pauseOnHover: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>
                <div class='about-us'>
                    <div className="event-booking-portal">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="text-center about-main mobile-reverse">
                                        <Fragment>
                                            <hgroup>
                                                <h2 class="pixter-title pixter-font">The worlds first AR event booking portal</h2>
                                                <p class="pixter-font">
                                                    Just like your current booking platform... but with shades on <span><img src={emoji} alt="emoji btn inage" /></span>
                                                </p>
                                            </hgroup>
                                            <video width="100%" style={{"objectFit": "cover" }} height="560" autoPlay muted loop playsInline>
                                                <source src={dummyVideo} type="video/mp4" />
                                            </video>
                                        </Fragment>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="align-items-center justify-content-center " >
                        <Container fluid>
                            <Row className="mobile-reverse">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="take-event justify-content-center d-flex align-items-center">
                                    <div className="take-tour-sec text-center">
                                        <h4>Take your <br className="show-mobile"/> Event to the  <br className="show-mobile"/>  Next Level,<br/> 
                                            <span>Promote  <br/> with Us !</span>
                                        </h4>
                                        <h6>Reach More Attendees, Create More Buzz.<br/>We don't just sell tickets, we help to create<br/>
                                            <span> The Ultimate Exprience!</span>
                                        </h6>
                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="justify-content-center d-flex flex-column inner-left-padding py-5">     
                                    <div className="inner-organizer ">
                                        <h4 className="signup-content">Sign up now for early access!<br/> <span>Limited spots for beta available.</span> </h4>
                                        <h5 ref={this.organzer}>Invites only Exclusive Early Access</h5>
                                        <p>Secure your exclusive invite code to promote<br/> your events on Scank before they're gone!</p>
                                        <p>Simply fill out the form below, to receive your <br/> unique code and more info via whatsapp.</p>
                                        <h5  >Early access comes with perks:</h5>
                                        <ul>
                                            <li>- Discounts on design @paperboygraphics</li>
                                            <li>- Free AR promo</li> 
                                            <li>- Exclusive marketing opportunities</li> 
                                        </ul>
                                        <p> Let's Make It Happen!</p>
                                    </div>
                                    <Form ref={this.form} className="scank-form invites--form" onSubmit={this.sendEmail}>
                                        <Row>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Group as={Col} controlId="formGridName">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="firstName"
                                                        value={formData.firstName}
                                                        onChange={(e) => this.handleChange('firstName', e.target.value)}
                                                        isInvalid={!!errors.firstName}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstName}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col> 
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Group as={Col} controlId="formGridName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="lastName"
                                                        value={formData.lastName}
                                                        onChange={(e) => this.handleChange('lastName', e.target.value)}
                                                        isInvalid={!!errors.lastName}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col> 
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Group as={Col} controlId="formGridnumber">
                                                    <Form.Label>
                                                        <img src={whatsappFill} alt="WhatsApp Icon" width={18} height={18} /> WhatsApp Number
                                                    </Form.Label>
                                                    <PhoneInput
                                                        country={'gb'} // Set default country
                                                        name="user_whatsapp"
                                                        value={formData.user_whatsapp}
                                                        onChange={(value) => this.handleChange('user_whatsapp', value)}
                                                        isInvalid={!!errors.user_whatsapp}
                                                        style={errors.user_whatsapp ? { borderColor: 'red' } : {}}
                                                    />
                                                    {errors.user_whatsapp && (
                                                        <div className="invalid-feedback" style={{display:"block"}}>
                                                        {errors.user_whatsapp}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Group as={Col} controlId="formGridcity">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control
                                                        name="user_city"
                                                        value={formData.user_city}
                                                        onChange={(e) => this.handleChange('user_city', e.target.value)}
                                                        isInvalid={!!errors.user_city}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.user_city}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Button className="w-100 btn-blue" variant="primary" type="submit" onClick={this.sendEmail}>
                                            Request invite Code
                                        </Button>
                                    </Form>
                                    {/* <div className="d-flex align-items-center justify-content-center">
                                        <div className="inner-download-section d-flex justify-content-center text-center">
                                            <hgroup>
                                                <h3 className="color-red">Give it a try, download now!  </h3>
                                                <h4>Follow the link on a desktop/laptop to scan the image.
                                                    If you're on a mobile, try using another device to scan it."</h4>
                                            </hgroup>
                                        </div>
                                        <div className="try-it">
                                            <button >
                                                <img src={Tryit} alt="try btn inage" />
                                            </button>
                                        </div>
                                    </div> */}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="scans-strips">
                        <b>Enter the portal for the ultimate experience</b>
                    </div>
                    <div className="components-section top-bottom-custom-padding">
                        <Container>
                            <Row className="h-100">
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="d-flex align-items-stretch">
                                    <div className="components-cards red-border w-100">
                                        <h4 className="mobile-font">
                                            Sell tickets <span><img src={Sellticket} alt="try btn image" /></span>
                                        </h4>
                                        <Card className="h-100">
                                            <Card.Body>
                                                <h4>Only 10% platform commission<br /><span>(min £1 per ticket).</span></h4>
                                                <p className="mt-3">No booking fees for free events</p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="d-flex align-items-stretch">
                                    <div className="components-cards red-border w-100">
                                        <h4 className="mobile-font">
                                            Monitor sale <span><img src={SaleMonitor} alt="try btn image" /></span>
                                        </h4>
                                        <Card className="h-100">
                                            <Card.Body>
                                                <p>
                                                    <span className="font-red d-content">Manage and monitor </span> your event on the go, with  <span className="font-red d-content"> SK Organiser <br/> app.</span>
                                                </p>
                                                <h5>Sales / wallet / guest list Ticket scan + more</h5>
                                                <img className="image-left-slightUp" src={ravers} alt="logo image" />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="d-flex align-items-stretch">
                                    <div className="components-cards w-100">
                                        <h4 className="mobile-font">
                                            Create a buzz <span><img src={buzz} alt="try btn image" /></span>
                                        </h4>
                                        <Card className="h-100">
                                            <Card.Body>
                                                <p>
                                                    Deliver <span className="font-red d-content"> unforgettable AR experiences </span> 
                                                    that wow your audience!
                                                </p>
                                                <p>Add <span className="font-red mobile-font-11">attraction icons</span> to highlight special features!</p>
                                                <img className="image-right-slight" src={sellout} alt="logos image" />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="manage-slider-section top-bottom-custom-padding bg-grey">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Slider {...settings}>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Slide1} alt="img" />
                                            <h6 className="pt-2">Manage </h6>
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Slide2} alt="img" />
                                            <h6 className="pt-2">Quickly View</h6>
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Slide3} alt="img" />
                                            <h6 className="pt-2">Track sales </h6>
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Slide4} alt="img" />
                                            <h6 className="pt-2">Scan in guests</h6>
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={Slide5} alt="img" />
                                            <h6 className="pt-2"> Guest list </h6>
                                        </div>
                                    </Slider>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="selling-sec top-bottom-custom-padding">
                        <button className="selling-btn" onClick={(e)=>{
                             e.preventDefault(); // Prevents the default action
                                 window.location.href = "https://www.scankportal.co.uk/";
                        }}>
                            I already have a code <br/>
                            <span>Start Selling Now</span>
                        </button>
                    </div>
                </div>
                <Modal centered className="invite-modal modal-dialog-centered" show={Modalshow} onHide={this.handleClose}>
                    <Modal.Body className="text-center">
                        <hgroup>
                            <h2 class="pixter-font">Success!</h2>
                            <p class="pixter-font invite--code">
                                Your invite code is on it's way!
                            </p>
                            <p class="pixter-font">
                            (Simply enter your code during sign-up to the organiser portal )
                            </p>
                            <button className="pixter-font" onClick={this.handleClose}> OK </button>
                        </hgroup>
                    </Modal.Body>
                </Modal>
            </>

        );
    }
}


function mapStateToProps(state) {
    return {
        isGetSiteContentSuccess: state.siteContentReducer.isGetSiteContentSuccess,
        isGetSiteContentError: state.siteContentReducer.isGetSiteContentError,
        siteContent: state.siteContentReducer.siteContent,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getSiteContent: (data) => dispatch(getSiteContent(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestCode);